import React from 'react';
import { graphql } from 'gatsby';
import { Container, Col, Row, Button, ButtonGroup } from 'react-bootstrap';

import Layout from '../components/layout';
import Cards from '../components/cards';
import Step from '../components/process/Step';
import SEO from '../components/Seo';

const GLink = ({ internal_link, external_link }) => {
  let link = external_link;
  if (!external_link) {
    if (internal_link.post_type === 'designer') {
      link = `/designers/${internal_link.post_name}`;
    } else if (internal_link.post_type === 'home_page') {
      link = `/`;
    } else {
      link = `/${internal_link.post_name}`;
    }
  }
  return (
    <Button
      className='button-padding'
      style={{ backgroundColor: 'transparent', border: 'none' }}
      href={link}
    >
      LEARN MORE{' '}
      <i style={{ marginLeft: '25px' }} className='fas fa-angle-right' />
    </Button>
  );
};

const ProcessPage = (props) => {
  const data = props.data.allWordpressAcfProcess.edges[0].node.acf;
  const steps = data.steps;

  return (
    <Layout className='process'>
      <SEO title='Our Process' />
      <div className='section section__island mt-md-20'>
        <div className='section--row'>
          <div className='top ml-md-20'>
            <h2 className='font-weight-bold' style={{ fontSize: '48px' }}>
              {data.top_content.title}
            </h2>
            <p className='mt-3'>{data.top_content.content}</p>
          </div>
        </div>
      </div>
      <div className='primary jumbotron jumbotron-fluid'>
        <div className='row bg-primary process-content'>
          <div className='container-fluid'>
            <Row className='justify-content-between'>
              <div className='col-md-3 d-flex flex-column justify-content-center text-white ml-md-20'>
                <h3 style={{ fontSize: '36px' }}>
                  {data.secondary_content.title}
                </h3>
                <p className='mt-3'>{data.secondary_content.content}</p>
              </div>
              <div className='col-md-6 process-img'>
                <img
                  style={{ width: '100%' }}
                  src={data.secondary_content.image.link}
                />
              </div>
            </Row>
          </div>
        </div>
      </div>
      <div className='section section__breathe'>
        <div className='section--row justify-content-around'>
          <Cards
            cards={data.cards.map((card) => ({
              block: 'process',
              title: card.title,
              content: card.content,
              image: card.card_image && card.card_image.source_url,
            }))}
          />
        </div>
      </div>
      <div className='section section__breathe'>
        {steps.map((step, ndx) => (
          <Step
            key={ndx}
            block='process'
            step_number={ndx + 1}
            justifyRight={ndx % 2 === 1}
            title={step.title}
            content={step.content}
            image={step.image}
          />
        ))}
      </div>
      <div className='section section__breathe  bg-primary text-white'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={6} className=' mt-5 mb-5'>
              <div dangerouslySetInnerHTML={{ __html: data.bottom_text }} />
              <ButtonGroup className='landing--button-group d-md-flex justify-content-around mt-5'>
                <Button
                  variant='outline-light'
                  className='upload-button'
                  style={{ borderRadius: '0' }}
                  href='/model'
                >
                  UPLOAD 3D MODEL
                </Button>
                <GLink {...data.bottom_text_link} />
              </ButtonGroup>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    allWordpressAcfProcess(limit: 1) {
      edges {
        node {
          acf {
            bottom_text
            top_content {
              title
              content
            }
            secondary_content {
              title
              content
              image {
                link: source_url
              }
            }
            cards {
              title
              content
              card_image {
                source_url
              }
            }
            bottom_text_link {
              internal_link {
                post_type
                post_name
              }
              external_link
            }
            steps {
              title
              content
              image {
                source_url
              }
            }
          }
        }
      }
    }
  }
`;

export default ProcessPage;
