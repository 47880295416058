import React from 'react';
import { Col, Row } from 'react-bootstrap';

const padNumberToTwoDigits = (nbr) => {
  return nbr.toLocaleString(undefined, {
    style: 'decimal',
    minimumIntegerDigits: 2,
  });
};

export default ({ step_number, justifyRight, title, content, image }) => {
  let first = '';
  let second = '';

  if (justifyRight) {
    first = 'order-lg-first';
    second = 'order-lg-second';
  }

  let imgClass = 'ml-md-5';

  if (justifyRight) {
    imgClass = 'mr-md-5';
  }

  return (
    <Row className='justify-content-around my-5'>
      <Col
        lg={2}
        className={`step-number d-flex flex-column justify-content-center ${second}`}
      >
        <h3 className='font-xl font-weight-bold text-primary text-center'>
          {padNumberToTwoDigits(step_number)}
        </h3>
      </Col>
      <Col
        lg={4}
        className={`d-flex flex-column justify-content-center ${second}`}
      >
        <h4
          style={{ fontSize: '36px' }}
          className='font-weight-bold text-center'
        >
          {title}
        </h4>
        <div
          className='step-definition mt-3'
          dangerouslySetInnerHTML={{ __html: content }}
          style={{ lineHeight: '34px' }}
        />
      </Col>
      {!!image ? (
        <Col
          lg={4}
          className={`d-flex flex-column justify-content-center ${first}`}
        >
          <div className={`img-fluid`}>
            <img style={{ width: '100%' }} src={image.source_url} />
          </div>{' '}
        </Col>
      ) : null}
    </Row>
  );
};
