import React from 'react';

const Card = ({ fontSize = '36px', block, image, title, content }) => {
  const cardClassName = block ? `${block}--card` : 'card';

  return (
    <div className={cardClassName}>
      <img className='card-img-top' src={image} alt='Card image cap' />
      <div className='card-body'>
        <h5 className='card-title' style={{ fontSize }}>
          {title}
        </h5>
        <div
          className='card-text'
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  );
};

const Cards = ({ cards, fontSize }) =>
  cards.map((card, index) => (
    <Card key={index} {...card} fontSize={fontSize} />
  ));

export default Cards;
